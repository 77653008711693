<template>
  <nav
    class="flex flex-wrap gap-5 justify-between px-7 py-3 w-full max-md:px-5 max-md:max-w-full"
  >
    <div
      class="flex flex-wrap gap-10 text-sm font-medium leading-none text-gray-600 max-md:max-w-full"
    >
      <div class="mt-2">
        <div v-html="COMPANY_LOGO"></div>
      </div>
      <div class="flex flex-auto gap-5 overflow-x-auto whitespace-nowrap">
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 whitespace-nowrap rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <BaseButton
              class="flex gap-2"
              type="button"
              @click="navigateToDashboard"
            >
              <div
                v-html="DASHBOARD_ICON"
                :class="{ 'text-primary-purple': activeTab == 0 }"
              ></div>
              <span
                class="self-stretch my-auto"
                :class="{ 'text-primary-purple': activeTab == 0 }"
                >Dashboard</span
              >
            </BaseButton>
          </div>
        </div>
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <BaseButton
              type="button"
              class="flex gap-2"
              @click="navigateToExpertise"
            >
              <div
                v-html="EXPERTISE_ICON"
                :class="{ 'text-primary-purple': activeTab == 1 }"
              ></div>
              <span
                class="self-stretch my-auto"
                :class="{ 'text-primary-purple': activeTab == 1 }"
                >My Expert Programs</span
              ></BaseButton
            >
          </div>
        </div>
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 whitespace-nowrap rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <BaseButton
              type="button"
              class="flex gap-2"
              @click="navigateToEarning"
            >
              <div
                v-html="EARNINGS_ICON"
                :class="{ 'text-primary-purple': activeTab == 2 }"
              ></div>

              <span
                class="self-stretch my-auto"
                :class="{ 'text-primary-purple': activeTab == 2 }"
                >My Earning</span
              >
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-4 self-start mt-1">
      <div class="flex">
        <div class="flex z-10 gap-3 items-center mr-0 font-medium">
          <div
            class="relative w-9 h-9 overflow-hidden rounded-full flex items-center justify-center"
          >
            <img
              v-if="authStore?.profileInformation.profilePicture"
              loading="lazy"
              :src="authStore?.profileInformation.profilePicture"
              alt="User profile picture"
              class="w-full h-full object-cover"
            />
            <img
              v-else
              loading="lazy"
              src="../../assets/shared/avatar.png"
              alt="User profile picture"
              class="w-full h-full object-cover"
            />
            <div class="absolute inset-0"></div>
          </div>
          <div class="flex flex-col self-stretch my-auto">
            <div class="text-sm leading-none text-gray-700">
              {{ authStore?.firstName }} {{ authStore?.lastName }}
            </div>
            <div class="text-xs leading-none text-gray-500">
              {{ authStore?.email }}
            </div>
          </div>
        </div>
        <div
          class="self-start text-xs font-black leading-none text-violet-600"
        ></div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import router from "@/router";
import { COMPANY_LOGO } from "@/assets/svg/shared/svgConstants";
import {
  EARNINGS_ICON,
  EXPERTISE_ICON,
  DASHBOARD_ICON,
} from "@/assets/svg/expert-overview/svgConstants";
import { defineComponent } from "vue";
import BaseButton from "../shared/BaseButton.vue";
import { useAuthStore } from "@/stores/auth";

export default defineComponent({
  name: "ExpertiseNavbar",
  components: {
    BaseButton,
  },
  props: {
    activeTab: Number,
  },
  setup() {
    const authStore = useAuthStore();

    const navigateToDashboard = () => {
      router.push({ name: "ExpertDashboard" });
    };

    const navigateToExpertise = () => {
      router.push({ name: "ExpertExpertise" });
    };

    const navigateToEarning = () => {
      // router.push({ name: "ExpertEarnings" });
      console.log("Coming Soon");
    };

    return {
      COMPANY_LOGO,
      EARNINGS_ICON,
      EXPERTISE_ICON,
      DASHBOARD_ICON,
      navigateToDashboard,
      navigateToExpertise,
      navigateToEarning,
      authStore,
    };
  },
});
</script>
